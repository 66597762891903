import React from 'react'
import {Input} from 'reactstrap';
import {Link} from 'react-router-dom'
import {AVATAR_STATIC_HOST} from "../constants";
import {AppContext} from "../store/context";
import {PositionLabel} from "../components/Text";


export default class Search extends React.Component {
    constructor() {
        super();

        this.state = {
            result: [],
            value: ''
        }
    }

    handleInput(e) {
        const query = e.currentTarget.value;

        this.setState({
            value: query
        });

        if (query.length >= 3) {
            this.context.context.processor.search.users(query).then(res => {
                this.setState({
                    result: res.users
                })
            })
        }
    }

    handleClick() {
        this.setState({
            result: [],
            value: ''
        })
    }

    render() {
        return <div className={'search'}>
            <Input value={this.state.value} type="text" onChange={(e) => {this.handleInput(e)}} name="query" id="query" placeholder="Поиск пользователя"/>
            <div className={`search-result ${this.state.result.length ? 'show' : ''}`}>
                {this.state.result ? this.state.result.map(el => {
                    return <Link to={`/profile/${el.username}`} onClick={() => {
                        this.handleClick()
                    }} className={'search-result-item'}>
                        <div className={'image'} style={{backgroundImage: `url("${el.image ? AVATAR_STATIC_HOST + el.image : ''}"`}}/>
                        <div className="description">
                            <span className={'name'}>{el.firstName} {el.lastName}</span>
                            <span className={'position'}><PositionLabel position={el.info.position} group={el.group}/></span>
                        </div>
                    </Link>
                }) : null}
            </div>
        </div>

    }
}

Search.contextType = AppContext;

