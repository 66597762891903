import React, {useContext, useState} from 'react'
import {Button, Col, Container, Row} from 'reactstrap'
import {MessageSquare, ArrowLeft} from 'react-feather'

import {AppContext} from "../store/context";
import {Link} from 'react-router-dom';
import nofoto from '../static/no_photo.png'

import {UnmountClosed} from 'react-collapse'
import {AVATAR_STATIC_HOST} from "../constants";
import Loader from '../components/Loader'

const ThreadCreate = ({callback}) => {
    const {context} = useContext(AppContext);

    const [title, setTitle] = useState('');
    const [text, setText] = useState('');

    const submit = () => {
        if (text.length == 0 || title.length == 0) {
            context.toast.error('Запись не может быть пустой');
            return;
        }

        context.processor.threads.create({title: title, text: text})
            .then(res => {
                context.toast.success('Запись успешно добавлена');

                callback(res.thread)
            })
            .catch(err => {
                context.toast.error('Что-то пошло не так.. Пожалуйста, попробуйте позже');
            })
    }

    return <div className="thread thread-create">
        <div className="thread-user">
            <img src={context.user.state.user.image ? AVATAR_STATIC_HOST + context.user.state.user.image : nofoto}
                 alt=""/>
        </div>
        <div className="thread-preview">
            <div className="thread-preview__meta">
                <div
                    className="thread-preview__username">{context.user.state.user.firstName} {context.user.state.user.lastName}</div>
                <div className="thread-preview__date">Черновик</div>
            </div>
            <div className="thread-preview__title">
                <input className="form-control"
                       placeholder={'Новая запись'}
                       value={title}
                       onChange={(e) => {
                           setTitle(e.currentTarget.value)
                       }}
                       type="text"/>
            </div>
            <div className="thread-preview__preview">
            <textarea className="form-control" rows={8}
                      onChange={(e) => {
                          setText(e.currentTarget.value)
                      }}
                      value={text}
                      placeholder={'Поделитесь своей новостью со всеми'} name=""/>
            </div>
            <div className="thread-buttons">
                <div className="thread-buttons__create">
                    <Button color={'primary'} size={'sm'} onClick={() => {
                        submit()
                    }}>Сохранить</Button>
                </div>
            </div>
        </div>
    </div>
}

const Comment = ({comment, slug, answer, openAnswer, onAnswer}) => {
    return <div className="comment">
        <div className="comment-head">
            <div className="comment-user">
                <div className="comment-user__image">
                    <img src={comment.user.image ? AVATAR_STATIC_HOST + comment.user.image : nofoto}
                         alt=""/>
                </div>
                <div className="comment-user__name">
                    {comment.user.firstName} {comment.user.lastName}
                </div>
            </div>
            <div className="comment-head__date">{comment.createdAt}</div>
        </div>
        <div className="comment-body">
            {comment.text}
        </div>
        <div className="comment-buttons">
            {answer === comment.id ?
                <div className="comment-buttons__reply" onClick={() => {
                    openAnswer(null)
                }}>
                    <span>Отмена</span>
                </div>
                :
                <div className="comment-buttons__reply" onClick={() => {
                    openAnswer(comment.id)
                }}>
                    <span>Ответить</span>
                </div>}
        </div>
        <div className="comment-children">
            <UnmountClosed isOpened={answer === comment.id}>
                <CommentCreate parent={comment.id} slug={slug} callback={onAnswer}/>
            </UnmountClosed>
            {comment.children.map(el => {
                return <Comment answer={answer} slug={slug} onAnswer={onAnswer} openAnswer={openAnswer} comment={el}/>
            })}
        </div>
    </div>
};

const CommentCreate = ({slug, parent, callback}) => {
    const {context} = useContext(AppContext);
    const [text, setText] = useState('');

    const submit = () => {
        context.processor.threads.comment(slug, {parent: parent, text: text}).then(res => {
            callback(res)
        }).catch(e => {
            context.toast.error('Что-то пошло не так.. Пожалуйста, попробуйте позже');
        })
    };

    return <div className="comment">
        <div className="comment-body">
             <textarea className="form-control" rows={4}
                       onChange={(e) => {
                           setText(e.currentTarget.value)
                       }}
                       value={text}
                       placeholder={'Ответ на комментарий'} name=""/>
        </div>
        <div className="comment-buttons">
            <Button color={'primary'} size={'sm'} onClick={() => {submit()}}>Опубликовать</Button>
        </div>
    </div>
};


export class BlogPage extends React.Component {
    constructor() {
        super();

        this.openCreate = this.openCreate.bind(this);
        this.closeCreate = this.closeCreate.bind(this);

        this.state = {
            create: false,
            list: [],
            loaded: false
        }
    }

    componentDidMount() {
        this.context.context.processor.threads.list().then(res => {
            this.setState({
                loaded: true,
                list: res.threads
            })
        })
    }

    openCreate() {
        this.setState({
            create: true
        })
    }

    closeCreate() {
        this.setState({
            create: false
        })
    }

    onCreate(thread) {
        const list = this.state.list;

        list.unshift(thread);

        this.setState({
            create: false,
            list: list
        })
    }

    render() {
        return <Container fluid className="p-5">
            <Row>
                <Col md={12} className={'d-flex justify-content-between'}>
                    <h3>Лента событий</h3>
                    {this.state.create ?
                        <Button color={'warning'} size={'sm'} onClick={() => {
                            this.closeCreate()
                        }}>Отменить</Button>
                        : <Button color={'primary'} size={'sm'} onClick={() => {
                            this.openCreate()
                        }}>Создать запись</Button>}
                </Col>
            </Row>
            {this.state.loaded ?
                <Row>
                    <Col md={12}>
                        <div className="threads">
                            <UnmountClosed isOpened={this.state.create}>
                                <ThreadCreate callback={(thread) => {
                                    this.onCreate(thread)
                                }}/>
                            </UnmountClosed>
                            {this.state.list.map(el => {
                                return <div className="thread">
                                    <div className="thread-user">
                                        <Link to={`/profile/${el.user.username}`}>
                                            <img src={el.user.image ? AVATAR_STATIC_HOST + el.user.image : nofoto} alt=""/>
                                        </Link>
                                    </div>
                                    <div className="thread-preview">
                                        <div className="thread-preview__meta">
                                            <div
                                                className="thread-preview__username">{el.user.firstName} {el.user.lastName}</div>
                                            <div className="thread-preview__date">{el.createdAt}</div>
                                        </div>
                                        <div className="thread-preview__title">
                                            <Link to={`/blog/${el.id}-${el.slug}`}>{el.title}</Link>
                                        </div>
                                        <div className="thread-preview__preview">{el.preview}</div>
                                        <div className="thread-buttons">
                                            <div className="thread-buttons__comment">
                                                <Link to={`/blog/${el.id}-${el.slug}`}>
                                                    <MessageSquare size={12}/> <span>Обсудить</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </Col>
                </Row>
                : <Loader/>
            }
        </Container>
    }
}

export class BlogSinglePage extends React.Component {
    constructor() {
        super();

        this.openAnswer = this.openAnswer.bind(this);
        this.onAnswer = this.onAnswer.bind(this);

        this.state = {
            thread: null,
            comments: [],
            answer: null,
            create: false,
            loaded: false
        }
    }

    componentDidMount() {
        this.context.context.processor.threads.single(this.props.match.params.slug).then(res => {
            this.setState({
                loaded: true,
                thread: res.thread,
                comments: res.comments
            })
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.slug !== prevProps.match.params.slug) {
            this.setState({
                thread: null,
                comments: [],
                create: false,
                loaded: false
            });

            setTimeout(() => this.componentDidMount(), 100)
        }
    }

    onAnswer() {
        this.componentDidMount();
        this.openAnswer(null);
        this.openForm(false);
    }

    openAnswer(id) {
        this.setState({answer: id})
    }

    openForm(open) {
        this.setState({create: open})
    }

    render() {
        return <Container fluid className="p-5">
            {this.state.loaded ?
                <Row>
                    <Col md={12}>
                        <Link to={`/blog/`} className={'blog-back'}><ArrowLeft size={18}/> Назад</Link>
                        <div className="threads">
                            <div className="thread">
                                <div className="thread-user">
                                    <Link to={`/profile/${this.state.thread.user.username}`}>
                                        <img
                                            src={this.state.thread.user.image ? AVATAR_STATIC_HOST + this.state.thread.user.image : nofoto}
                                            alt=""/>
                                    </Link>
                                </div>
                                <div className="thread-preview">
                                    <div className="thread-preview__meta">
                                        <div
                                            className="thread-preview__username">{this.state.thread.user.firstName} {this.state.thread.user.lastName}</div>
                                        <div className="thread-preview__date">{this.state.thread.createdAt}</div>
                                    </div>
                                    <div className="thread-preview__title">{this.state.thread.title}</div>
                                    <div className="thread-preview__text">{this.state.thread.text}</div>
                                </div>
                            </div>
                        </div>
                        <div className="comments">
                            <div className="comments-title">
                                <span>Комментарии</span>
                                {this.state.create
                                    ? <Button size={"sm"} color="warning" onClick={() => this.openForm(false)}>Отмена</Button>
                                    : <Button size={"sm"} color="primary" onClick={() => this.openForm(true)}>Написать</Button>}
                            </div>
                            <UnmountClosed isOpened={this.state.create}>
                                <CommentCreate slug={this.props.match.params.slug} parent={null} callback={this.onAnswer}/>
                            </UnmountClosed>
                            {this.state.comments.map(el => {
                                return <Comment comment={el} slug={this.props.match.params.slug} answer={this.state.answer} openAnswer={this.openAnswer} onAnswer={this.onAnswer}/>
                            })}
                        </div>
                    </Col>
                </Row>
                : <Loader/>
            }
        </Container>
    }
}


BlogPage.contextType = AppContext;
BlogSinglePage.contextType = AppContext;
