import {AuthAware} from "../auth-aware";
import {API_BASE_URL} from "../../constants";

export class HooksApi extends AuthAware {
    getBaseUrl() {
        return `${API_BASE_URL}/hooks`;
    }

    getEmployees() {
        return this.fetchJSON(`${this.getBaseUrl()}/employees`)
    }

    createEmployeesRequest(req) {
        return this.fetchJSON(`${this.getBaseUrl()}/employees/create-request`,{
            method: 'post',
            body: JSON.stringify(req)
        })
    }
}
