export const UserInitialState = {
    user: {},
    isAuth: false,
    loaded: false
};

export const UserReducer = (state, action) => {
    switch (action.type) {
        case 'setUser':
            return {...state, user: action.payload, isAuth: true, loaded: true};
        case 'userLoaded':
            return {...state, loaded: true};
        case 'updateImage' :
            return {...state, user:{...state.user, image: action.payload}}
        default:
            throw new Error();
    }
};
