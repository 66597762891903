export const PositionLabel = ({position, group}) => {
    const parts = [];

    if (position) {
        parts.push(position)
    }

    if (group) {
        parts.push(group)
    }

    return parts.join(', ')
}