import React, {useContext, useState} from "react";
import {Badge, Col, Collapse, Container, Input, Row} from 'reactstrap'
import TreeMenu, {ItemComponent} from 'react-simple-tree-menu';
import {Link} from 'react-router-dom'
import {ChevronDown, ChevronUp} from 'react-feather'

import {AppContext} from "../store/context";
import {AVATAR_STATIC_HOST} from '../constants'
import Loader from '../components/Loader'

import nofoto from '../static/no_photo.png'
import '../../node_modules/react-simple-tree-menu/dist/main.css';

export class StructurePage extends React.Component {
    constructor() {
        super();

        this.state = {
            groups: [],
            items: [],
            users: [],
            loaded: false
        }
    }

    componentDidMount() {
        this.context.context.processor.structure.getList().then(res => {
            const nodes = [];
            const buildMap = (item) => {
                return {
                    key: "1",
                    ulid: item.ulid,
                    label: item.name,
                    nodes: item.children.map((el) => buildNodes(el))
                }
            };

            const buildNodes = (item) => {
                return {
                    key: item.ulid,
                    ulid: item.ulid,
                    label: item.name,
                    nodes: item.children.map((el) => buildNodes(el))
                }
            };

            res.groups.map(item => {
                nodes.push(buildMap(item));
            });

            this.setState({
                nodes: nodes,
                loaded: true
            });
        }).catch(err => {
            this.context.context.toast.error(err.message)
        })
    }

    loadUsers(ulid) {
        this.context.context.processor.structure.getUsers(ulid).then(res => {
            const users = res.users;

            users.sort((x, y) => {
                return (x.isManager === y.isManager) ? 0 : x.isManager ? -1 : 1
            });

            this.setState({
                users: users,
            })
        });
    }

    desktopStructure = () => {
        return <Row>
            <Col md={6}>
                <StructureHolder callback={(ulid) => this.loadUsers(ulid)} items={this.state.nodes}/>
            </Col>
            <Col md={6} className={'structure-users'}>
                {this.state.users.length ? <UsersHolder users={this.state.users}/> : null}
            </Col>
        </Row>
    };

    mobileStructure = () => {
        return <Row>
            <Col xs={12}>
                <div className="structure-items">
                    {this.state.nodes.map(el => {
                        return <MobileStructureNode el={el} level={0}/>
                    })}
                </div>
            </Col>
        </Row>
    };

    render() {
        return <Container fluid className="p-5">
            {this.state.loaded === true ?
                this.context.context.settings.isDesktop
                    ? this.desktopStructure()
                    : this.mobileStructure()
                : <Loader/>
            }
        </Container>
    }
}

const MobileStructureNode = ({el, level}) => {
    const {context} = useContext(AppContext);

    const [users, setUsers] = useState([]);
    const [isOpen, setOpen] = useState(false);

    const toggleClick = (ulid) => {
        if (isOpen) {
            setOpen(false);

            return;
        }

        context.processor.structure.getUsers(ulid).then(res => {
            const users = res.users;

            users.sort((x, y) => {
                return (x.isManager === y.isManager) ? 0 : x.isManager ? -1 : 1
            });

            setUsers(users)
        }).then(() => {
            setOpen(true)
        })
    };

    return <div className={`structure-item structure-item--level-${level} ${isOpen ? 'structure-item--active' : ''}`}>
        <div className="structure-item--title" onClick={() => {
            toggleClick(el.ulid)
        }}>
            <span className="structure-item--title-text">{el.label}</span>
            <span className="structure-item--title-arrow">
                {isOpen ? <ChevronUp/> : <ChevronDown/>}
                </span>
        </div>
        <Collapse isOpen={isOpen} className="structure-item--inner">
            <div className="structure-item--inner-users">
                {users.length ? <UsersHolder users={users}/> : null}
            </div>
            <div className="structure-item--inner-children">
                {el.nodes.length ? el.nodes.map(e => {
                    return <MobileStructureNode el={e} level={level + 1}/>
                }) : null}
            </div>
        </Collapse>
    </div>
};

const StructureHolder = ({items, callback}) => {
    return <div className="structure">
        <TreeMenu data={items} initialOpenNodes={"1"}
                  onClickItem={({key, label, ...props}) => {
                      callback(props.ulid);
                  }}>
            {({search, items}) => (
                <>
                    <Input onChange={e => search(e.target.value)} placeholder="Поиск"/>
                    <div className={"structure-items"}>
                        {items.map(({key, ...props}) => (
                            <>
                                <ItemComponent className={'structure-item'} key={key} {...props} />
                            </>
                        ))}
                    </div>
                </>
            )}
        </TreeMenu>
    </div>
};

const UsersHolder = ({users}) => {
    return <div className={'structure-users__holder'}>
        {users.map(el => {
            return <Link to={`/profile/${el.username}`}
                         className={`structure-user ${el.isManager ? 'structure-user-manager' : ''}`}>
                <div className="structure-user__image">
                    <span className='image'
                          style={{backgroundImage: `url("${el.info && el.image ? AVATAR_STATIC_HOST + el.image : nofoto}"`}}/>
                </div>
                <div className="structure-user__info">
                    <div className="structure-user__info-title">{el.lastName} {el.firstName} {el.patronymic}</div>
                    <div className="structure-user__info-position d-flex align-items-center">
                        {el.info.position ? <span className={"position"}>{el.info.position}</span> : null}
                        {el.info.tag ? <Badge color={'tag'}>{el.info.tag}</Badge> : null}
                    </div>
                </div>
            </Link>
        })}
    </div>
};

StructurePage.contextType = AppContext;