import React from 'react'
import {Card, CardBody, CardHeader, Col, Container, Row} from 'reactstrap'
import {Link} from 'react-router-dom'

import {AppContext} from "../store/context";
import {AVATAR_STATIC_HOST, EMPLOYEES_STATIC_HOST} from "../constants";
import nofoto from '../static/no_photo.png'

import Loader from "../components/Loader";

export class BestEmployeesPage extends React.Component {
    constructor() {
        super();

        this.state = {
            loaded: false,
            blocks: []
        }
    }

    componentDidMount() {
        this.context.context.processor.employees.getList().then(res => {
            this.setState({
                loaded: true,
                blocks: res.items
            })
        })
    }

    render() {
        return <Container fluid className={"mb-5"}>
            {this.state.loaded ?
                <>
                    {this.state.blocks.map(el => {
                        return el.type === 'big' ? <BigBlock item={el}/> : null
                    })}
                    <SmallBlocks blocks={this.state.blocks}/>

                </>
                : <Loader/>}

        </Container>
    }
}

const SmallBlocks = ({blocks}) => {
    const mapping = [];

    blocks.forEach(el => {
        if (el.type !== 'small') {
            return;
        }

        let exist = mapping.find(m => {
            return m.grouping === el.grouping
        });

        if (exist) {
            exist.items.push(el);
            return;
        }

        mapping.push({
            grouping: el.grouping,
            items: [el]
        })
    })

    return <div className="employees-block pt-5">
        {mapping.map(item => {
            return <div className="employees-block pt-5">
                <Row>
                    <Col md={12} className={"text-center"}>
                        <h3>{item.grouping}</h3>
                    </Col>
                </Row>
                {item.items.map(el => {
                    return <SmallBlock item={el}/>
                })}
            </div>
        })}
    </div>
}

const BigCard = ({item}) => {
    return <Col md={6} className={"mt-4"}>
        <Card>
            <CardBody>
                <Row>
                    <Col md={6}>
                        <div className="employees-block__image"
                             style={{backgroundImage: `url("${item.image
                                     ? EMPLOYEES_STATIC_HOST + item.image
                                     : item.profile_pic
                                         ? AVATAR_STATIC_HOST + item.profile_pic
                                         : nofoto
                                     }")`}}
                        />
                        <div className="employees-block__name">
                            <Link to={`/profile/${item.slug}`}>
                                {item.name}
                            </Link>
                        </div>
                        <div className="employees-block__position">
                            {item.position}
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="employees-block__description">
                            {item.description}
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    </Col>
}

const BigBlock = ({item}) => {
    return <div className="employees-block pt-5">
        <Row>
            <Col md={12} className={"text-center"}>
                <h3>{item.title}</h3>
            </Col>
        </Row>
        <Row className={"mt-2"}>
            {item.employees.map(el => {
                return <BigCard item={el}/>
            })}
        </Row>
    </div>
}


const SmallBlock = ({item}) => {
    return <div className="employees-block pt-5">
        <Row className={"mt-2"}>
            <Col md={12}>
                <Card>
                    <CardHeader className={"text-center"}>{item.title}</CardHeader>
                    <CardBody>
                        <Row>
                            {item.employees.map(el => {
                                return <SmallCard item={el}/>
                            })}
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </div>
}

const SmallCard = ({item}) => {
    return <Col md={3} className={"mt-4"}>
        <Col md={12}>
            <div className="employees-block__image"
                 style={{backgroundImage: `url("${item.image
                         ? EMPLOYEES_STATIC_HOST + item.image
                         : item.profile_pic
                             ? AVATAR_STATIC_HOST + item.profile_pic
                             : nofoto
                         }")`}}
            />
            <div className="employees-block__name">
                <Link to={`/profile/${item.slug}`}>
                    {item.name}
                </Link>
            </div>
        </Col>
    </Col>
}

BestEmployeesPage.contextType = AppContext;