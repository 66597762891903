import React, {useContext, useState} from 'react';
import {Collapse, Nav, Navbar, NavbarText, NavbarToggler, NavItem} from 'reactstrap';
import {Link} from 'react-router-dom'

import Search from './Search'
import {AppContext} from "../store/context";

import {AVATAR_STATIC_HOST} from "../constants";

import nofoto from '../static/no_photo_small.png'
import logo from '../static/logo.png'

const NavBar = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const {context} = useContext(AppContext);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <div>
            <Navbar color="light" light expand="md">
                <Link className="navbar-brand" to={`/profile/${context.user.state.user.username}`}>
                    <img src={logo} alt=""/>
                </Link>
                <NavbarToggler onClick={toggle}/>
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="mr-auto" navbar>
                        <NavItem>
                            <Link className={'nav-link'} to="/blog/">Лента событий</Link>
                        </NavItem>
                        <NavItem>
                            <Link className={'nav-link'} to={`/profile/${context.user.state.user.username}`}>Мой
                                профиль</Link>
                        </NavItem>
                        <NavItem>
                            <Link className={'nav-link'} to="/structure/">Оргструктура</Link>
                        </NavItem>
                        <NavItem>
                            <Link className={'nav-link'} to="/shop/">Маркет</Link>
                        </NavItem>
                        <NavItem>
                            <Link className={'nav-link'} to="/regulations/">Информация</Link>
                        </NavItem>
                        <NavItem>
                            <Link className={'nav-link'} to="/best-employees/">Лучшие сотрудники</Link>
                        </NavItem>

                        {context.user.state.user.isManager ?
                            <NavItem>
                                <Link className={'nav-link'} to="/requests/">Заявки</Link>
                            </NavItem> : null}
                    </Nav>
                    <NavbarText>
                        <Search/>
                        {context.settings.isDesktop ?
                            <Link to={`/profile/${context.user.state.user.username}`}>
                                <div className="nav-profile-img"
                                     style={{backgroundImage: `url(${context.user.state.user.image ? AVATAR_STATIC_HOST + context.user.state.user.image : nofoto})`}}/>
                            </Link> : null}
                    </NavbarText>
                </Collapse>
            </Navbar>
        </div>
    );
};

export default NavBar;