import {AuthAware} from "../auth-aware";
import {API_BASE_URL} from "../../constants";

export class ThreadsApi extends AuthAware {
    getBaseUrl() {
        return `${API_BASE_URL}/threads`;
    }

    list() {
        return this.fetchJSON(`${this.getBaseUrl()}/`)
    }

    single(slug) {
        return this.fetchJSON(`${this.getBaseUrl()}/${slug}`)
    }

    create(data) {
        return this.fetchJSON(`${this.getBaseUrl()}/`, {
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    comment(slug, data) {
        return this.fetchJSON(`${this.getBaseUrl()}/${slug}/comment`, {
            method: 'POST',
            body: JSON.stringify(data),
        })
    }
}