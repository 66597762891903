import {createContext, useReducer, useState, useEffect} from "react";
import {toast} from 'react-toastify'

import {Processor} from "../api/public.processor";
import {UserInitialState, UserReducer} from "./reducers";

export const AppContext = createContext();
export const AppContextValue = () => {
    const [userState, userDispatch] = useReducer(UserReducer, UserInitialState);
    const isDesktop = useCheckMobileScreen();

    return {
        settings: {
            isDesktop: isDesktop
        },
        user: {
            state: userState,
            dispatch: userDispatch
        },
        toast: toast,
        processor: Processor()
    }
};


const useCheckMobileScreen = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return (width > 768);
};
