import {AuthAware} from "../auth-aware";
import {API_BASE_URL} from "../../constants";

export class ShopApi extends AuthAware{
    getBaseUrl() {
        return `${API_BASE_URL}/shop`;
    }

    getCatalog() {
        return this.fetchJSON(`${this.getBaseUrl()}/products`)
    }

    getItem(ulid) {
        return this.fetchJSON(`${this.getBaseUrl()}/products/${ulid}`)
    }

    buyItem(ulid) {
        return this.fetchJSON(`${this.getBaseUrl()}/products/${ulid}/buy`)
    }
}