import {AuthAware} from "../auth-aware";
import {API_BASE_URL} from "../../constants";

export class RegulationsApi extends AuthAware {
    getBaseUrl() {
        return `${API_BASE_URL}/regulations`;
    }

    getList() {
        return this.fetchJSON(`${this.getBaseUrl()}/`)
    }
}