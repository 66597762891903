import {AuthAware} from "../auth-aware";
import {API_BASE_URL} from "../../constants";

export class StructureApi extends AuthAware {
    getBaseUrl() {
        return `${API_BASE_URL}/group`;
    }

    getList() {
        return this.fetchJSON(`${this.getBaseUrl()}/`)
    }

    getUsers(ulid) {
        return this.fetchJSON(`${this.getBaseUrl()}/${ulid}/users`)
    }
}