import React, {useContext} from "react";
import {Button} from "reactstrap";
import {Link} from "react-router-dom";

import {AppContext} from "../store/context"

export const Page404 = () => {
    const {context} = useContext(AppContext);

    return <div className="text-center">
        <h1 className="display-1 font-weight-bold">404</h1>
        <p className="h1">Увы</p>
        <p className="h2 font-weight-normal mt-3 mb-4">
            Эту страницу мы еще не нарисовали
        </p>
        {context.user.state.isAuth
            ? <Link to={`/profile/${context.user.state.user.username}`}>
                <Button color="primary" size="lg">Вернуться в профиль</Button>
            </Link>
            : <Link to={`/auth/signin`}><Button color="primary" size="lg">Вернуться</Button></Link>
        }
    </div>
};

