import React from "react";
import {X as Cross} from 'react-feather'
import {Alert, Card, CardBody, CardTitle, Form, FormFeedback, FormGroup, Input} from "reactstrap";

import {AppContext} from "../store/context";

export class AuthSignIn extends React.Component {
    constructor() {
        super();

        this.handleChange = this.handleChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.validate = this.validate.bind(this);

        this.state = {
            disabled: false,
            errors: {
                password: null,
                username: null
            },
            data: {
                password: "",
                username: "",
            },
        }
    }

    componentWillMount() {
        if (this.context.context.user.state.isAuth) {
            this.props.history.replace(`/profile/${this.context.context.user.state.user.username}`);
        }
    }

    handleChange(e) {
        e.preventDefault();

        let value = e.target.value;
        let name = e.target.name;
        let data = this.state.data;

        data[name] = value;

        this.setState({
            data: data
        });
    }

    handleFormSubmit(e) {
        e.preventDefault();

        if (false === this.validate()) {
            return;
        }

        this.setState({
            disabled: true,
            authError: false,
        });

        this.context.context.processor.auth.login(this.state.data)
            .then(res => {
                this.context.context.user.dispatch({type: 'setUser', payload: res.user});

                return res.user
            })
            .then(res => this.props.history.replace(`/profile/${res.username}`))
            .catch(err => {
                this.context.context.toast.warn(err.message);
                this.setState({
                    disabled: false,
                });
            })
    }

    validate() {
        const errors = {
            username: null,
            password: null,
        };

        if (this.state.data.username === "") {
            errors.username = "Логин не должен быть пустым"
        }

        if (this.state.data.password === "") {
            errors.password = "Пароль не должен быть пустым"
        }

        this.setState({
            errors: errors
        });

        return errors.username === null && errors.password === null;
    }

    render() {
        return (
            <Card>
                <CardTitle>
                    <div className="text-center mt-4">
                        <h2>Войти в аккаунт</h2>
                        <p className={"small"}>Впервые здесь? Обратитесь к администратору, <br/>чтобы получить данные
                            для входа</p>
                    </div>
                </CardTitle>
                <CardBody>
                    <div className="m-sm-4">
                        <div className="text-center">
                        </div>
                        <Form>
                            <FormGroup>
                                <Input
                                    invalid={this.state.errors.username !== null}
                                    bsSize="lg"
                                    type="text"
                                    name="username"
                                    required={true}
                                    placeholder="Логин"
                                    onChange={this.handleChange}
                                    disabled={this.state.disabled}
                                />
                                {this.state.errors.username !== null ? (
                                    <FormFeedback>{this.state.errors.username}</FormFeedback>
                                ) : null}
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    invalid={this.state.errors.password !== null}
                                    bsSize="lg"
                                    type="password"
                                    name="password"
                                    required={true}
                                    placeholder="Пароль"
                                    onChange={this.handleChange}
                                    disabled={this.state.disabled}
                                />
                                {this.state.errors.password !== null ? (
                                    <FormFeedback>{this.state.errors.password}</FormFeedback>
                                ) : null}
                            </FormGroup>
                            <div className="text-center mt-3">
                                <div className={"btn btn-primary"}
                                     onClick={this.handleFormSubmit}>
                                    <div className="sign-up__button-text"> Войти</div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </CardBody>
            </Card>
        );
    }
}

AuthSignIn.contextType = AppContext;