import React, {useContext} from "react";
import NavBar from "../components/NavBar"
import {Col, Container, Row} from "reactstrap";
import classNames from "classnames";
import {AppContext} from "../store/context";
import {ToastContainer} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const Main = ({className, children}) => (
    <div className={classNames("main", className)}>{children}</div>
);

const CorpLayout = ({children}) => {
    const {context} = useContext(AppContext);

    if (!context.user.state.isAuth) {
        window.location.replace('/auth/signin');
        return;
    }

    return <React.Fragment>
        <NavBar/>
        <Main className="d-flex w-100 justify-content-center">
            <Container className="d-flex flex-column">
                <Row className="h-100">
                    <Col md="12" className="mx-auto d-table h-100">
                        <div className="d-table-cell align-middle">{children}</div>
                    </Col>
                </Row>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </Container>
        </Main>
    </React.Fragment>
};

export default CorpLayout;
