import React from 'react'
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from 'reactstrap'
import {Target} from 'react-feather'
import {ProductImagesSlider} from '../components/Slider'
import {PRODUCT_STATIC_HOST} from "../constants";

import Loader from "../components/Loader";
import {AppContext} from "../store/context";

import nofoto from '../static/no_photo_small.png'

export class ShopPage extends React.Component {
    constructor() {
        super();

        this.state = {
            buyModal: {
                isOpen: false,
                success: false
            },
            detailsModal: {
                isOpen: false,
                product: {}
            },
            products: [],
            loaded: false
        }
    }

    componentDidMount() {
        this.context.context.processor.shop.getCatalog().then(res => {
            this.setState({
                products: res.products,
                loaded: true
            })
        });
    }

    handleBuy(e) {
        const ulid = e.currentTarget.dataset.ulid || null;

        if (!ulid) {
            this.context.context.toast.warn('Продукт не найден!')
            return;
        }

        this.context.context.processor.shop.buyItem(ulid).then(res => {
            this.context.context.toast.success(res.message)
        }).then(() => {
            if (this.state.detailsModal.isOpen) this.toggleModal();
        }).catch(err => {
            this.context.context.toast.warn(err.message)
        })
    }

    handleDetail(ulid) {
        this.context.context.processor.shop.getItem(ulid).then(res => {
            this.setState({
                detailsModal: {
                    ...this.state.detailsModal,
                    product: res.product
                },
            })
        }).then(() => {
            this.toggleModal()
        })
    }

    toggleModal() {
        this.setState({
            detailsModal: {
                ...this.state.detailsModal,
                isOpen: !this.state.detailsModal.isOpen
            },
        });
    }

    render() {
        return <Container fluid className="p-5">
            <Row>
                <Col md={12} className={'text-center'}>
                    <h3>Система поощрений GoodCoin</h3>
                    <p className={'m-4 f14'}>Чем активнее участвуешь, тем больше гудкоинов получаешь. <br/>Ты можешь
                        копить гудкоины, передавать коллегам со "спасибо" или тратить на понравившиеся товары из
                        магазина</p>
                    <hr/>
                </Col>
            </Row>
            {this.state.loaded === true ?
                <Row>
                    {this.state.products.map(el => {
                        return <Col md={3} className={'mt-4'}>
                            <Card className="shop-product flex-fill w-100">
                                <CardHeader className='shop-product__title'>
                                    <div className="shop-product__name">{el.name}</div>
                                </CardHeader>
                                <CardBody>
                                    <div onClick={() => {
                                        this.handleDetail(el.ulid)
                                    }}
                                         className='shop-product__img'
                                         style={{backgroundImage: `url("${el.image ? PRODUCT_STATIC_HOST + el.image : nofoto}")`}}/>
                                    <div className="shop-product__price">{el.price}
                                        <Target className={"ml-1"} size={"20"}/>
                                    </div>
                                    <Button data-ulid={el.ulid}
                                            onClick={() => {
                                                this.handleDetail(el.ulid)
                                            }}
                                            block
                                            className={'shop-product__button mt-2'}
                                            color={'primary'}>Подробнее</Button>
                                </CardBody>
                            </Card>
                        </Col>
                    })}
                </Row> : <Loader/>
            }
            <Modal isOpen={this.state.detailsModal.isOpen} toggle={() => {
                this.toggleModal()
            }}>
                <ModalHeader toggle={() => {
                    this.toggleModal()
                }}>Обменять гудкоины</ModalHeader>
                <ModalBody className="details-modal">
                    <div className="details-modal__col">
                        <ProductImagesSlider product={this.state.detailsModal.product}/>
                        <div
                            className="details-modal__remains">В
                            наличии {this.state.detailsModal.product.count || ''} шт.
                        </div>
                    </div>
                    <div className="details-modal__col">
                        <div className="details-modal__descr">
                            <strong>{this.state.detailsModal.product.name || ''}</strong> <br/>
                            {this.state.detailsModal.product.description || 'Без описания'}</div>
                        <div className="details-modal__price">{this.state.detailsModal.product.price || ''}
                            <Target color={'red'} className={"ml-1"} size={"25"}/>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button data-ulid={this.state.detailsModal.product.ulid || ''}
                            onClick={(e) => {
                                this.handleBuy(e)
                            }} color='primary' type="submit" block disabled={false}>Обменять</Button>
                </ModalFooter>
            </Modal>
        </Container>
    }
}

ShopPage.contextType = AppContext;