import React from 'react'
import {
    Badge,
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from 'reactstrap'
import {Info, Mail, Map, Phone, Star, Target, User} from 'react-feather'
import {Link} from 'react-router-dom'


import {AppContext} from "../store/context";
import {PositionLabel} from "../components/Text";
import {AVATAR_STATIC_HOST, AWARD_STATIC_HOST} from "../constants";
import Loader from "../components/Loader"

import nofoto from '../static/no_photo.png'

export class ProfilePage extends React.Component {
    constructor() {
        super();

        this.handleFileChange = this.handleFileChange.bind(this);

        this.state = {
            isProfile: false,
            user: null,
            events: null,
            balance: null,
            awards: null,
            editModal: {
                isOpen: false,
                fields: {
                    phone: null,
                    phone_additional: null,
                    city: null,
                    bio: null,
                },
            },
            thankModal: {
                isOpen: false,
                amount: null,
                comment: '',
            },
            imageModal: {
                file: null,
                loading: false,
                isOpen: false,
            },
        }
    }

    componentDidMount() {
        let username = this.props.match.params.username;
        if (!username) {
            username = this.context.context.user.state.user.username
        }

        this.context.context.processor.profile.getProfile(username)
            .then(res => {
                this.setState({
                    isProfile: this.context.context.user.state.user.username === username,
                    user: res.user,
                    events: res.events,
                    balance: res.balance,
                    awards: res.awards,
                    editModal: {
                        isOpen: false,
                        fields: {
                            phone: res.user.info.phone,
                            phone_additional: res.user.info.phoneAdditional,
                            city: res.user.info.city,
                            bio: res.user.info.bio,
                        },
                    },
                })
            })
            .then(() => {
                const params = new URLSearchParams(this.props.location.search);

                if (params.get('success')) {
                    this.context.context.toast.success(decodeURI(params.get('success')))
                }

                if (params.get('error')) {
                    this.context.context.toast.error(decodeURI(params.get('error')))
                }
            })
            .catch(err => {
                window.location.replace("/");
            })
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.username !== prevProps.match.params.username) {
            this.setState({
                user: null,
                events: null,
                balance: null
            });

            setTimeout(() => this.componentDidMount(), 100)
        }
    }

    profilePicture = ({pic, balance, isProfile}) => {
        return (
            <div>
                <div className="profile-image"
                     onClick={() => {
                         this.toggleImageModal()
                     }}
                     style={{backgroundImage: `url("${pic ? AVATAR_STATIC_HOST + pic : nofoto}")`}}/>
                <div className="profile-actions mt-2">
                    {isProfile ?
                        <div>
                            <Button disabled outline color={"danger"} block
                                    className={'d-flex justify-content-center balance-button'}>
                                <span className={'d-flex align-items-center'}>
                                    <div className={'balance-button__label'}>Доступно гудкоинов:</div>
                                    <div className={'balance-button__value'}>
                                        {balance}
                                        <Target className={"ml-1"} size={"15"} color={"red"}/>
                                    </div>
                                </span>
                            </Button>
                            <Button onClick={() => {
                                this.toggleEditModal()
                            }} outline color={"secondary"} block className={'d-flex justify-content-center'}>
                                <span className={'d-flex align-items-center'}>Редактировать профиль</span>
                            </Button>
                            <Button onClick={() => {
                                this.handleLogout()
                            }} outline color={"secondary"} block className={'d-flex justify-content-center'}>
                                <span className={'d-flex align-items-center'}>Выйти из профиля</span>
                            </Button>
                        </div>

                        : <Button onClick={() => {
                            this.toggleThankModal()
                        }} outline color={"primary"} block className={'d-flex justify-content-center'}>
                            <span className={'d-flex align-items-center'}>Поблагодарить</span>
                        </Button>
                    }
                </div>
            </div>
        )
    };

    toggleEditModal() {
        if (!this.state.isProfile) {
            return
        }

        this.setState({
            ...this.state,
            editModal: {
                ...this.state.editModal,
                isOpen: !this.state.editModal.isOpen
            },
        });
    }

    toggleThankModal() {
        const data = {
            isOpen: !this.state.thankModal.isOpen
        };

        if (this.state.thankModal.isOpen === false) {
            data.comment = '';
        }

        this.setState({
            ...this.state,
            thankModal: data,
        });
    }

    toggleImageModal() {
        if (!this.state.isProfile) {
            return
        }

        this.setState({
            ...this.state,
            imageModal: {
                ...this.state.imageModal,
                isOpen: !this.state.imageModal.isOpen
            },
        });
    }

    handleLogout() {
        this.context.context.processor.auth.logout();

        window.location.replace('/auth/signin');

        return;
    }

    handleEdit(e) {
        e.preventDefault();

        this.context.context.processor.profile.edit(this.state.editModal.fields).then(() => {
            this.componentDidMount();
            this.toggleEditModal();
        }).then(() => {
            this.context.context.toast.success('Профиль отредактирован');
        }).catch((err) => {
            this.context.context.toast.error(err.message);
        })
    }

    handleThank(e) {
        e.preventDefault();

        const parsed = parseInt(this.state.thankModal.amount, 10);
        const amount = isNaN(parsed) ?  0: parsed;

        this.context.context.processor.profile.thank(this.state.user.username, this.state.thankModal.comment, amount).then(() => {
            this.componentDidMount();
            this.toggleThankModal();
        }).then(() => {
            this.context.context.toast.success('Благодарность отправлена!');
        }).catch((err) => {
            this.context.context.toast.error(err.message);
        })
    }

    handleThankModalChange(e) {
        const target = e.currentTarget;

        switch (target.name) {
            case 'amount':
                this.setState({thankModal: {...this.state.thankModal, amount: target.value}});
                break;
            case 'comment':
                this.setState({thankModal: {...this.state.thankModal, comment: target.value}});
                break;
        }
    }

    handleEditModalChange(e) {
        const target = e.currentTarget;

        const fields = this.state.editModal.fields;

        if (fields.hasOwnProperty(target.name)) {
            fields[target.name] = target.value;

            this.setState({
                ...this.state,
                editModal: {
                    ...this.state.editModal,
                    fields: fields
                }
            });
        }
    }

    handleFileChange(event) {
        this.setState({
            imageModal: {
                ...this.state.imageModal,
                file: event.target.files[0]
            }
        });
    };

    handleUpload(e) {
        e.preventDefault();

        const formData = new FormData();

        if (!this.state.imageModal.file) {
            this.context.context.toast.error('Пожалуйста, выберите изображение для загрузки');
            return
        }

        formData.append(
            'file',
            this.state.imageModal.file,
            this.state.imageModal.file.name
        );

        this.setState({
            imageModal: {
                ...this.state.imageModal,
                loading: true
            }
        });

        this.context.context.processor.profile.uploadAvatar(formData)
            .then((res) => {
                this.context.context.user.dispatch({type: 'updateImage', payload: res.image});

                this.componentDidMount()
            })
            .then(() => {
                this.toggleImageModal();
                this.context.context.toast.success('Изображение профиля успешно обновлено');
            })
            .catch((err) => {
                this.context.context.toast.error(err.message);
            })
    }

    render() {
        return <Container fluid className="p-5">
            {this.state.user ?
                <Row>
                    <Col xs={12} md={12} lg={4} className={'profile-picture__holder'}>
                        <Card className="flex-fill w-100">
                            <CardBody>
                                {this.profilePicture({
                                    pic: this.state.user.image,
                                    balance: this.state.balance,
                                    isProfile: this.state.isProfile
                                })}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={12} md={12} lg={8} className={'profile-info__holder'}>
                        <Card className="flex-fill w-100">
                            <CardBody>
                                <div className="profile-info">
                                    <div className="profile-info--title">
                                        <h4>{this.state.user.lastName} {this.state.user.firstName} {this.state.user.patronymic}</h4>
                                        <span className={"small align-items-center"}>
                                            {this.state.user.info.tag ?
                                                <Badge color={'tag--small'}>{this.state.user.info.tag}</Badge>
                                                : <span><PositionLabel position={this.state.user.info.position} group={this.state.user.group}/></span>
                                            }
                                        </span>
                                    </div>
                                    <div className="profile-info--icon">
                                        <User size={30} stroke-width={1.5}/>
                                    </div>
                                </div>
                                <hr/>
                                <div className="profile-contacts">
                                    {this.state.user.info.phone ?
                                        <div className="profile-contacts-row">
                                            <div className="title">
                                                <Phone size={16}
                                                       className={'mr-2'}/><span>Телефон</span></div>
                                            <div className="value">
                                                <a href={`tel:${this.state.user.info.phone}`}>
                                                    {this.state.user.info.phone}
                                                </a>
                                            </div>
                                        </div> : null}
                                    {this.state.user.info.phoneAdditional ?
                                        <div className="profile-contacts-row">
                                            <div className="title">
                                                <Phone size={16}
                                                       className={'mr-2'}/><span>Доп. телефон</span></div>
                                            <div className="value">
                                                <a href={`tel:${this.state.user.info.phoneAdditional}`}>
                                                    {this.state.user.info.phoneAdditional}
                                                </a>
                                            </div>
                                        </div> : null}
                                    <div className="profile-contacts-row">
                                        <div className="title"><Mail size={16} className={'mr-2'}/><span>Email</span>
                                        </div>
                                        <div className="value"><a
                                            href={`mailto:${this.state.user.info.email}`}>{this.state.user.info.email}</a>
                                        </div>
                                    </div>
                                    {this.state.user.info.city ?
                                        <div className="profile-contacts-row">
                                            <div className="title"><Map size={16} className={'mr-2'}/><span>Город</span>
                                            </div>
                                            <div className="value">{this.state.user.info.city}</div>
                                        </div> : null}
                                    {this.state.user.info.bio ?
                                        <div className="profile-contacts-row bio">
                                            <div className="title">
                                                <Info size={16} className={'mr-2'}/>
                                                <span>Обо мне:</span>
                                            </div>
                                            <div className="value">{this.state.user.info.bio}</div>
                                        </div> : null}
                                </div>
                            </CardBody>
                        </Card>
                        {this.state.awards.length ?
                            <Card className="flex-fill w-100 mt-4">
                                <CardBody>
                                    <div className="profile__awards">
                                        {this.state.awards.map(el => {
                                            return <div className="profile__award">
                                                <div className="profile__award--image">
                                                    <img src={AWARD_STATIC_HOST + el.award.image} alt=""/>
                                                    {el.count > 1 ? <span
                                                        className={"profile__award--count"}>{el.count}</span> : null}
                                                </div>
                                                <div className="profile__award--title">{el.award.title}</div>
                                            </div>
                                        })}
                                    </div>

                                </CardBody>
                            </Card> : null}
                        {this.state.events.length ?
                            <Card className="flex-fill w-100 mt-4">
                                <CardBody>
                                    <div className={'profile-events'}>
                                        <h4>События</h4>
                                        <Star stroke-width={1.5} size={30}/>
                                    </div>
                                    {this.state.events.map(el => {
                                        if (el.type === 'thank') {
                                            return <div className={"profile-event profile-event--thank"}>
                                                <div className='profile-event__body'>
                                                    <div className='profile-event__body-info'>
                                                        <div className='profile-event__body-title'>
                                                            <Link
                                                                to={`/profile/${el.event.from.username}`}>{el.event.from.text}</Link>
                                                            <span className="fw-normal">поблагодарил(а)</span>
                                                            <Link
                                                                to={`/profile/${el.event.to.username}`}>{el.event.to.text}</Link>
                                                        </div>
                                                        <div
                                                            className={'profile-event__body-description'}>{el.event.comment}</div>
                                                    </div>
                                                </div>
                                                {el.event.amount > 0
                                                    ? <div className={"profile-event__amount"}>
                                                        <span
                                                            className={"profile-event__amount-value"}>+ {el.event.amount}</span>
                                                        <Target className={"profile-event__amount-icon"} size={"20"}
                                                                color={"red"}/>
                                                    </div>
                                                    : null
                                                }
                                                <div className="profile-event__date">
                                                    {(new Date(el.createdAt.date)).toLocaleDateString('ru-RU')}
                                                </div>
                                            </div>
                                        }

                                        if (el.type === 'award') {
                                            return <div className={"profile-event profile-event--award"}>
                                                <div className="profile-event__body">
                                                    <div className="profile-event__body-image">
                                                        <img src={AWARD_STATIC_HOST + el.event.image} alt=""/>
                                                    </div>
                                                    <div className='profile-event__body-info'>
                                                        <div
                                                            className='profile-event__body-title'>{el.event.title}</div>
                                                        <div
                                                            className='profile-event__body-description'>{el.event.comment}</div>
                                                    </div>
                                                </div>
                                                {el.event.amount > 0
                                                    ? <div className={"profile-event__amount"}>
                                                        <span
                                                            className={"profile-event__amount-value"}>+ {el.event.amount}</span>
                                                        <Target className={"profile-event__amount-icon"} size={"20"}
                                                                color={"red"}/>
                                                    </div>
                                                    : null
                                                }
                                                {this.context.context.settings.isDesktop ?
                                                    <div className="profile-event__date">
                                                        {(new Date(el.createdAt.date)).toLocaleDateString('ru-RU')}
                                                    </div> : null}
                                            </div>
                                        }
                                    })}
                                </CardBody>
                            </Card> : null}
                    </Col>
                    <Modal isOpen={this.state.thankModal.isOpen} toggle={() => {
                        this.toggleThankModal()
                    }}>
                        <ModalHeader toggle={() => {
                            this.toggleThankModal()
                        }}>Поблагодарить
                        </ModalHeader>
                        <Form onSubmit={(e) => this.handleThank(e)}>
                            <ModalBody className="m-3">
                                <p><Label>Комментарий</Label><Input type="textarea" onChange={(e) => {
                                    this.handleThankModalChange(e)
                                }} value={this.state.thankModal.comment} name="comment"/></p>
                                <p><Label>Количество гудкоинов</Label><Input type="number" onChange={(e) => {
                                    this.handleThankModalChange(e)
                                }} value={this.state.thankModal.amount} min={0} name="amount" placeholder={"0"}/></p>
                            </ModalBody>
                            <ModalFooter>
                                <Button color='primary' type="submit" disabled={false}>
                                    Отправить
                                </Button>
                            </ModalFooter>
                        </Form>
                    </Modal>
                    <Modal isOpen={this.state.editModal.isOpen} toggle={() => {
                        this.toggleEditModal()
                    }}>
                        <ModalHeader toggle={() => {
                            this.toggleEditModal()
                        }}>Редактировать профиль
                        </ModalHeader>
                        <Form onSubmit={(e) => this.handleEdit(e)}>
                            <ModalBody className="m-3">
                                <p>
                                    <Label>Телефон</Label>
                                    <Input type="text"
                                           onChange={(e) => {
                                               this.handleEditModalChange(e)
                                           }}
                                           value={this.state.editModal.fields.phone}
                                           min={0}
                                           name="phone"/>
                                </p>
                                <p>
                                    <Label>Добавочный номер</Label>
                                    <Input
                                        type="text"
                                        onChange={(e) => {
                                            this.handleEditModalChange(e)
                                        }} value={this.state.editModal.fields.phone_additional}
                                        min={0}
                                        name="phone_additional"/>
                                </p>
                                <p>
                                    <Label>Город</Label>
                                    <Input type="text"
                                           onChange={(e) => {
                                               this.handleEditModalChange(e)
                                           }}
                                           value={this.state.editModal.fields.city}
                                           min={0}
                                           name="city"/>
                                </p>
                                <p>
                                    <Label>Комментарий</Label>
                                    <Input type="textarea"
                                           onChange={(e) => {
                                               this.handleEditModalChange(e)
                                           }}
                                           value={this.state.editModal.fields.bio}
                                           name="bio"/>
                                </p>

                            </ModalBody>
                            <ModalFooter>
                                <Button color='primary' type="submit" disabled={false}>
                                    Отправить
                                </Button>
                            </ModalFooter>
                        </Form>
                    </Modal>
                    {this.state.isProfile ?
                    <Modal isOpen={this.state.imageModal.isOpen} toggle={() => {
                        this.toggleImageModal()
                    }}>
                        <ModalHeader toggle={() => {
                            this.toggleImageModal()
                        }}>Изменить аватар</ModalHeader>
                        <Form onSubmit={(e) => this.handleUpload(e)}>
                            <ModalBody className="m-3">
                                <input type="file" onChange={this.handleFileChange}/>
                            </ModalBody>
                            <ModalFooter>
                                <Button color='primary' type="submit" disabled={false}>
                                    Отправить
                                </Button>
                            </ModalFooter>
                        </Form>
                    </Modal> : null}
                </Row>
                : <Loader/>
            }
        </Container>
    }
}

ProfilePage.contextType = AppContext;
