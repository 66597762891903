import {API_BASE_URL} from "../constants";
import Token from "./../components/static/token";

export class AuthAware {
    refreshToken() {
        let headers = {};

        headers['Accept'] = 'application/json';
        headers['Content-Type'] = 'application/json';

        return fetch(`${API_BASE_URL}/auth/token/refresh`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                'refresh_token': Token.getRefreshToken(),
            })
        })
            .then(response => this.checkStatus(response))
            .then(response => response.json())
            .then(res => {
                Token.setToken(res.token);
            }).catch(err => {
                Token.logout();

                throw err
            })
    }

    async checkStatus(response) {
        if (response.status >= 200 && response.status < 300) {
            return response
        }

        if (response.status === 401 || response.status === 400) {
            let json = await response.json();
            let error = new Error(json.message);
            error.code = response.status;

            throw error
        }

        if (response.status > 401) {
            let error = new Error(response.statusText);
            error.code = response.status;

            throw error
        }
    }

    async fetchJSON(url, options, omitType) {
        if (options === undefined) {
            options = {};
        }

        options.credentials = 'include';

        let headers = options.headers || {};

        if (Token.getToken()) {
            headers['Authorization'] = 'Bearer ' + Token.getToken()
        }

        if (omitType !== true) {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
        }

        try {
            let response = await fetch(url, {headers, ...options})
                .then(response => this.checkStatus(response))
                .then(response => response.json());

            return response
        } catch (e) {
            if (e.code === 401) {
                await this.refreshToken();

                headers['Authorization'] = 'Bearer ' + Token.getToken();

                return fetch(url, {headers, ...options})
                    .then(response => this.checkStatus(response))
                    .then(response => response.json())
            }

            throw e;
        }


    }
}