import React from "react";
import {ToastContainer} from 'react-toastify';
import { Col, Container, Row } from "reactstrap";
import classNames from "classnames";

const Main = ({ className, children }) => (
    <div className={classNames("main", className)}>{children}</div>
);

const AuthLayout = ({ children }) => (
  <React.Fragment>
    <Main className="d-flex w-100 h-100 justify-content-center">
      <Container className="d-flex flex-column">
        <Row className="h-100">
          <Col sm="10" md="8" lg="6" className="mx-auto d-table h-100">
            <div className="d-table-cell align-middle">{children}</div>
          </Col>
        </Row>
          <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
          />
      </Container>
    </Main>
  </React.Fragment>
);

export default AuthLayout;
