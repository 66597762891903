import AuthLayout from "../layouts/AuthLayout"
import CorpLayout from "../layouts/CorpLayout"
import {AuthSignIn} from "../pages/AuthForm";
import {ProfilePage} from "../pages/Profile";
import {ShopPage} from "../pages/Shop"
import {SearchPage} from "../pages/Search";
import {StructurePage} from "../pages/Structure";
import {RequestsPage} from "../pages/Requests"
import {RegulationsPage} from '../pages/Regulations'
import {BestEmployeesPage} from '../pages/BestEmployees'
import {BlogPage, BlogSinglePage} from '../pages/BlogPage'

export const authRoutes = [
    {
        path: "/auth/signin",
        layout: AuthLayout,
        component: AuthSignIn
    },
];

export const publicRoutes = [
    {
        path: ["/", "/profile/:username"],
        layout: CorpLayout,
        component: ProfilePage
    },
    {
        path: "/blog/",
        layout: CorpLayout,
        component: BlogPage
    },
    {
        path: "/blog/:slug",
        layout: CorpLayout,
        component: BlogSinglePage
    },
    {
        path: "/shop/",
        layout: CorpLayout,
        component: ShopPage
    },
    {
        path: "/search/",
        layout: CorpLayout,
        component: SearchPage
    },
    {
        path: "/structure/",
        layout: CorpLayout,
        component: StructurePage
    },
    {
        path: "/structure/:ulid",
        layout: CorpLayout,
        component: StructurePage
    },
    {
        path: "/requests/",
        layout: CorpLayout,
        component: RequestsPage
    },
    {
        path: "/regulations/",
        layout: CorpLayout,
        component: RegulationsPage
    },
    {
        path: "/best-employees/",
        layout: CorpLayout,
        component: BestEmployeesPage
    },

];

export default [
    ...authRoutes,
    ...publicRoutes
]
