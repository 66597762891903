import {AuthAware} from "../auth-aware";
import {API_BASE_URL} from "../../constants";

export class SearchApi extends AuthAware{
    getBaseUrl() {
        return `${API_BASE_URL}/search`;
    }

    users(query) {
        return this.fetchJSON(`${this.getBaseUrl()}/?query=${query}`);
    }
}