import React from 'react'
import {Alert, Container, Row} from 'reactstrap'

import Loader from "../components/Loader";
import {AppContext} from "../store/context";

export class SearchPage extends React.Component {
    constructor() {
        super();

        this.state = {
            loaded: false,
            items: [],
            alert: {
                show: false,
                color: '',
                message: ''
            }
        }
    }

    componentDidMount() {
        const query = this.props.match.params.query;

        this.context.context.processor.search.users(query).then(res => {
           this.setState({
               items: res.items
           })
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.query !== prevProps.match.params.query) {
            this.setState({user: null, balance: null});

            setTimeout(() => this.componentDidMount(), 100)
        }
    }

    render() {
        return <Container fluid className="p-5">
            {this.state.alert.show ? <Alert className={'main-alert'}
                                            color={this.state.alert.color}>{this.state.alert.message}</Alert> : null}
            {this.state.loaded === true ?
                <Row>
                    {this.state.items.map(el => {

                    })}
                </Row> : <Loader/>
            }
        </Container>
    }
}

SearchPage.contextType = AppContext;