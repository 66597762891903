import React from "react";
import {Button, Card, CardBody, CardFooter, CardHeader, Container} from 'reactstrap'
import {Target} from 'react-feather'

import {AppContext} from "../store/context";
import Loader from "../components/Loader";
import {AVATAR_STATIC_HOST} from "../constants";

import nofoto from '../static/no_photo_small.png'

export class RequestsPage extends React.Component {
    constructor() {
        super();

        this.state = {
            loaded: false,
            group: null,
            users: [],
            request: {}
        }
    }

    componentDidMount() {
        if (!this.context.context.user.state.user.isManager) {
            this.props.history.push(`/?error=${encodeURI("Похоже, вы заблудились..")}`);

            return;
        }

        this.context.context.processor.hooks.getEmployees().then(res => {
            const request = {};

            res.users.forEach(el => {
                request[el.id] = {id: el.id, value: 0};
            });

            this.setState({
                loaded: true,
                group: res.group,
                users: res.users,
                request: request
            })
        }).catch(err => {
            this.context.context.toast.error(err.message)
        })
    }

    handleCreateRequest() {
        this.context.context.processor.hooks.createEmployeesRequest(this.state.request)
            .then(res => {
                this.props.history.push(`/?success=${encodeURI(res.message)}`)
            })
            .catch(err => {
                this.context.context.toast.error(err.message)
            })
    }

    handleChange(e) {
        const target = e.currentTarget;
        const key = target.dataset.key;

        let value = target.value;

        if (value >= 10) {
            value = 10;
        }

        if (value < 0) {
            value = 0;
        }

        const request = this.state.request;

        request[key].value = value;

        this.setState({
            ...this.state,
            request: request
        });
    }

    render() {
        return <Container fluid className="p-5">
            {this.state.loaded ?
                <Card xs={12}   className={'employees'}>
                    <CardHeader className={'employees__head'}>
                        <div>
                            <h5 className="card-title">Заявка на выдачу гудкоинов сотрудникам</h5>
                            <h6 className="card-subtitle mb-2 text-muted">Группа {this.state.group.name}</h6>
                        </div>
                        <div className="employees__icon"><Target color={'red'} size={36}/></div>
                    </CardHeader>
                    <CardBody>
                        {this.state.users.length ? this.state.users.map(user => {
                            return <div className={"employee-row"}>
                                <div className="employee-item">
                                    <div className="employee__user">
                                        <div className="employee__image"
                                             style={{backgroundImage: `url("${user.image ? AVATAR_STATIC_HOST + user.image : nofoto}")`}}/>
                                        <div className="employee__info">
                                            <div className="employee__name">{user.lastName} {user.firstName}</div>
                                            <div
                                                className="employee__position text-muted">{user.group} ({user.info.position})
                                            </div>
                                        </div>
                                    </div>
                                    <div className="employee__input">
                                        <input type="number" className={'form-control'} data-key={user.id}
                                               onChange={(e) => {
                                                   this.handleChange(e)
                                               }} value={this.state.request[user.id].value} max={10} min={0} placeholder={'0'}/>
                                    </div>
                                </div>
                            </div>
                        }) : <p>Увы, у вас нет сотрудников :(</p>}
                    </CardBody>
                    <CardFooter>
                        <Button onClick={() => {
                            this.handleCreateRequest()
                        }} color={'success'}>Отправить на согласование</Button>
                    </CardFooter>
                </Card>
                : <Loader/>}
        </Container>
    }
}


RequestsPage.contextType = AppContext;