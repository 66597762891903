import {AuthAware} from "../auth-aware";
import {API_BASE_URL} from "../../constants";

export class ProfileApi extends AuthAware {
    getBaseUrl() {
        return `${API_BASE_URL}/profile`;
    }

    getProfile(username) {
        return this.fetchJSON(`${this.getBaseUrl()}/${username}`);
    }

    thank(username, comment, amount) {
        return this.fetchJSON(`${this.getBaseUrl()}/${username}/thank`, {
            method: 'POST',
            body: JSON.stringify({comment, amount}),
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    edit(fields) {
        const data = {
            phone_additional: fields.phone_additional || null,
            phone: fields.phone || null,
            bio: fields.bio || null,
            city: fields.city || null
        };

        return this.fetchJSON(`${this.getBaseUrl()}/edit`, {
            method: 'POST',
            body: JSON.stringify({...data}),
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    uploadAvatar(data) {
        return this.fetchJSON(`${this.getBaseUrl()}/uploadAvatar`, {
            method: 'POST',
            body: data,
        }, true);
    }
}
