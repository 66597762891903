import {API_BASE_URL} from "../../constants";
import Token from "./../../components/static/token";
import {AuthAware} from "../auth-aware";

export class AuthApi extends AuthAware {
    login({username, password}) {
        return fetch(`${API_BASE_URL}/auth/signin`, {
            method: 'POST',
            body: JSON.stringify({username, password}),
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => this.checkStatus(res))
            .then(res => res.json())
            .then(res => {
                Token.setToken(res.token);
                Token.setRefreshToken(res.refresh_token);

                return res.data;
            })
    }

    logout() {
        Token.logout()
    }
    //
    // async checkStatus(response) {
    //     if (response.status >= 200 && response.status < 300) {
    //         return response
    //     }
    //
    //     if (response.status === 401) {
    //         let json = await response.json();
    //
    //         let error = new Error(json.message);
    //         error.code = response.status;
    //
    //         throw error
    //     }
    //
    //     if (response.status >= 400) {
    //         let error = new Error(response.statusText);
    //         error.code = response.status;
    //
    //         throw error
    //     }
    // }
}