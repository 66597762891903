export const SCHEMA = 'https';
export const DOMAIN = 'team.goodcom.ru';

export const API_BASE_URL = `${SCHEMA}://${DOMAIN}/api/v1`;
export const STATIC_HOST = `${SCHEMA}://${DOMAIN}/upload`;

export const AWARD_STATIC_HOST = `${STATIC_HOST}/awards/`;
export const AVATAR_STATIC_HOST = `${STATIC_HOST}/avatars/`;
export const PRODUCT_STATIC_HOST = `${STATIC_HOST}/products/`;
export const REGLAMENTS_STATIC_HOST = `${STATIC_HOST}/reglaments/`;

export const EMPLOYEES_STATIC_HOST = `${STATIC_HOST}/employees/`;