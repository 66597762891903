import Cookies from "js-cookie";
import decode from "jwt-decode";

export default class Token {
    static setRefreshToken(token) {
        Cookies.set('refresh_token', token, {expires: 30})
    }

    static getRefreshToken() {
        return Cookies.get('refresh_token')
    }

    static removeRefreshToken() {
        Cookies.remove('refresh_token');
    }

    static setToken(token) {
        Cookies.set('token', token, {expires: 1})
    }

    static getToken() {
        return Cookies.get('token')
    }

    static removeToken() {
        Cookies.remove('token');
    }

    static isExpired() {
        try {
            let decoded = decode(this.getToken());

            return decoded.exp < Date.now() / 1000;
        } catch (err) {
            return true;
        }
    }

    static logout() {
        this.removeToken();
        this.removeRefreshToken();
    }
}