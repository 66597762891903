import React from 'react'
import Tree from 'react-animated-tree'

import {AppContext} from "../store/context";
import {Card, CardBody, CardHeader, Col, Container, Row} from 'reactstrap'
import {REGLAMENTS_STATIC_HOST} from '../constants'
import {File, Folder} from 'react-feather'

import Loader from "../components/Loader";

export class RegulationsPage extends React.Component {
    constructor() {
        super();

        this.state = {
            loaded: false,
            items: []
        }
    }

    componentDidMount() {
        this.context.context.processor.regulations.getList().then(res => {
            this.setState({
                loaded: true,
                items: res.items
            })
        })
    }


    render() {
        return <Container fluid className="p-5">

            {this.state.loaded ?
                <Row>
                    <Col xs={12}>
                        <Card className={"regulations-card"}>
                            <CardHeader>
                                <div>
                                    <h5 className="card-title">Документы</h5>
                                    <h6 className="card-subtitle mb-2 text-muted">Список регламентов компании</h6>
                                </div>
                                <div className="card-header-icon"><File color={'black'} size={36}/></div>
                            </CardHeader>
                            <CardBody>
                                {this.state.items.map(el => {
                                    return <RegulationsNode name={el.name} folders={el.children} files={el.items}/>
                                })}

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                : <Loader/>}
        </Container>
    }
}

const RegulationsNode = ({name, folders, files}) => {
    const folder = (name) => {
        return <span className={'regulations-folder'}>
            <Folder size={20} color={'#ffba31'}/>
            <span>{name}</span>
        </span>
    };

    const file = ({path, name, ext}) => {
        return <span className={'regulations-file'}>
            <File size={20}/>
            <a target={"_blank"} href={REGLAMENTS_STATIC_HOST + path}>{name}.{ext}</a>
        </span>
    };

    return <Tree type={folder(name)}>
        {folders.map(el => {
            return <RegulationsNode name={el.name} folders={el.children} files={el.items}/>
        })}
        {files.map(el => {
            return <Tree type={file(el)}/>
        })}
    </Tree>
};


RegulationsPage.contextType = AppContext;