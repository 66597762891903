import {ProfileApi} from "./internal/profile.api";
import {ShopApi} from "./internal/shop.api";
import {SearchApi} from "./internal/search.api";
import {StructureApi} from "./internal/structure.api";
import {AuthApi} from './internal/auth.api'
import {UserApi} from "./internal/user.api";
import {HooksApi} from "./internal/hooks.api";
import {RegulationsApi} from "./internal/regulations.api";
import {EmployeesApi} from "./internal/employees.api";
import {ThreadsApi} from "./internal/threads.api";

export const Processor = () => {
    return {
        auth: new AuthApi(),
        shop: new ShopApi(),
        user: new UserApi(),
        search: new SearchApi(),
        profile: new ProfileApi(),
        structure: new StructureApi(),
        hooks: new HooksApi(),
        regulations: new RegulationsApi(),
        employees: new EmployeesApi(),
        threads: new ThreadsApi()
    }
};