import {AuthAware} from "../auth-aware";
import {API_BASE_URL} from "../../constants";

export class UserApi extends AuthAware {
    getBaseUrl() {
        return `${API_BASE_URL}/user`;
    }

    getUser() {
        return this.fetchJSON(`${this.getBaseUrl()}/`)
    }
}
