import {useEffect, useState} from 'react'
import Routes from './routing/routes'
import {AppContext, AppContextValue} from './store/context'


const App = () => {
    const context = AppContextValue();

    useEffect(() => {
        context.processor.user.getUser().then(res => {
            context.user.dispatch({type: 'setUser', payload: res.user});
        }).catch(err => {
            context.user.dispatch({type: 'userLoaded'});
        })
    }, []);

    return <AppContext.Provider value={{context}}>
       <Routes/>
    </AppContext.Provider>
};

export default App;
