import {useState} from 'react'
import {PRODUCT_STATIC_HOST} from '../constants'
import arrow_left from "../static/arrow_left.svg"
import arrow_right from "../static/arrow_right.svg"
import nofoto from '../static/no_photo_small.png'

export const ProductImagesSlider = ({product}) => {
    const [translate, setTranslate] = useState(0);
    const [index, setIndex] = useState(0);

    const items = [];

    if (product.image) {
        items.push(PRODUCT_STATIC_HOST + product.image)
    }

    if (product.images && product.images.length > 0) {
        product.images.map(el => {
            items.push(PRODUCT_STATIC_HOST + el)
        })
    }

    if(items.length === 0) {
        items.push(nofoto)
    }

    const shift = 250;

    const previous = () => {
        const prevIndex = index - 1;

        if (prevIndex < 0) {
            const lastIndex = items.length - 1;

            setIndex(lastIndex);
            setTranslate((lastIndex) * -shift);
            return;
        }

        setTranslate(translate + shift);
        setIndex(prevIndex)
    };

    const next = () => {
        const nextIndex = index + 1;

        if (nextIndex >= items.length) {
            setIndex(0);
            setTranslate(0);
            return;
        }

        setTranslate(translate - shift);
        setIndex(nextIndex)
    };


    return <div className="product-images">
        <div className="product-images__holder">
            <div className="product-images__slider">
                {items.map((el, i) => {
                    return <div style={{transform: `translate(${translate}px, ${0}px)`}}
                                className={`slide ${i === index ? 'slide-active' : ''}`}>
                        <div className="product-images__slider-item"
                             style={{backgroundImage: `url('${el}')`}}>
                        </div>
                    </div>
                })}
            </div>
        </div>
        {items.length > 1 ?
        <div className='product-images__arrows'>
            <div className="product-images__arrow slider-arrow" onClick={previous}>
                <img src={arrow_left} alt=""/>
            </div>
            <div className="product-images__arrow slider-arrow" onClick={next}>
                <img src={arrow_right} alt=""/>
            </div>
        </div> : null}
    </div>
}